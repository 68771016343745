/* form_manual_user_container */
.form_manual_user_container{
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(221, 219, 218, 1);
    border-radius: 0.25rem;
    padding: 0.5rem;
    /* min-height: 100px; */
    /* max-height: 350px; */
    /* overflow: auto; */
}