
.homeNotifications__element{
    background-color: white;
    padding: 10px 5px 10px 20px;
    margin-bottom: 5px;
}

.homeNotifications__elementHeader{
    display: flex;
    justify-content: space-between;
}

.homeNotifications__elementLabel{
    display: flex;
    align-items: center;
}

.homeNotifications__elementIcon{
    background-color: rgba(243, 110, 131, 1);
    color: white;
    padding: 2px;
    border-radius: 5px;
    margin-right: 5px;
}

.homeNotifications__elementLabel > h6 {
    font-size: 0.8rem;
    margin: 0;
}

.homeNotifications__elementInfo{
    display: flex;
    justify-content: space-between;
    margin: 5px 0px;
}

.homeNotifications__elementInfo > h6{
    font-size: 0.8rem;
    color: gray;
}

.homeNotifications__elementFooter{
    display: flex;
    align-items: center;
}

.homeNotifications__elementFooter > h6 {
    font-size: 0.8rem;
    color: gray;
    margin: 0;
}