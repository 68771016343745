.vertical-line {
  border-left: 2px solid rgb(92, 199, 92);
  display: inline-block;
  min-height: 100px;
  margin: 0px -29px 0px 20px;
}

.vertical-line-forUp {
  border-left: 2px solid rgb(137, 191, 213);
  display: inline-block;
  min-height: 183px;
  margin: 0px -29px 0px 20px;
}
.vertical-line-forCreation {
  border-left: 2px solid rgb(0, 98, 211);
  display: inline-block;
  min-height: 163px;
  margin: 0px -29px 0px 20px;
}

.timeLine {
  width: 100%;
  background: white;
  min-height: 745px;
}

.time-Line-Content {
  overflow-y: auto;
}
.time-Line-Content .time-line-bloc {
  height: 800px;
  padding-top: 10px;
}
.time-Line-Content .modification-bloc {
  height: 100vh;
}
.time-Line-Content .signature-bloc {
  height: 800px;
}
.status-container {
  padding: 0px 10px 0px 10px;
  width: 100%;
  display: flex;
}
.status-container .status-left {
  width: 12%;
}
.status-container .status-left .status-left-icon {
  margin-left: 11px;
}
.status-container .status-left .status-left-icon-forUp {
  margin-left: 10px;
}
.status-container .status-left .status-left-icon-forCreation {
  margin-left: 11px;
}
.status-container .status-right {
  padding: 5px 0px 0px 0px;
  width: 79%;
}
.status-container .status-right .status-right-top {
  display: flex;
  justify-content: space-between;
}
.status-container .status-right .status-right-top .parag {
  font-weight: 600;
  color: rgb(60 138 218);
  margin-bottom: 5px;
}
.status-container .status-right .status-right-middle {
  border: 1px solid lightgray;
  border-radius: 6px;
  background: aliceblue;
  padding: 10px;
  margin-top: 3px;
}
.status-container .status-right .status-right-middle .info-name {
  display: flex;
}
.status-container .status-right .status-right-middle .info-name p {
  min-width: 153px;
}

.status-container .status-right .status-right-middle .info-values {
  display: flex;
  color: rgb(60 138 218);
}
.status-container .status-right .status-right-middle .info-values p {
  min-width: 153px;
}

.signing-side {
  padding: 13px 10px 13px 13px;
  margin: 7px;
  border: 1px solid lightgray;
  border-radius: 4px;
}
.signing-side .signe-content {
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 10px 10px 10px 10px;
  margin-bottom: 10px;
}
.signing-side .signe-content p {
  color: darkgray;
}
.signing-side .signe-content .signe-bloc {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
}

.signing-side .signe-content .signe-bloc .signe-bloc-left h2 {
  color: rgb(60 138 218);
}
.signing-side .signe-content .signe-bloc .signe-bloc-left p {
  color: black;
  font-size: medium;
}
.signing-side .signe-content .signe-bloc .signe-bloc-left p span {
  color: black;
}




.timelineCard__container {
  display: flex;
  width: 100%;
  margin-bottom: 2px;
}

.timelineCard__hideicon{
  cursor: pointer;
}

.timelineCard__sideContainer{
  width: 100%;
}

.timelineCard__header{
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.timelineCard__header_label {
  font-weight: 600;
  color: rgb(0, 112, 210);
  margin-left: 1rem;
  flex: 1;
}

.timelineCard__header_time {
  justify-self: flex-end;
  font-size: 0.8rem;
  color: #706E6B;
}

.timelineCard__header_hour {
  border-right: 1px solid #706E6B;
  padding-right: 5px;
}

.timelineCard__header_date{
  padding-left: 5px;
}

.timelineCard__content{
  /* border-left: 2px solid rgb(0, 112, 210); */
  margin-left: 0.7rem;
}

.timelineCard__content__info {
  margin-left: 1.8rem;
  padding: 0.5rem 0;
}

.timelineCard__content__body{
  padding: 1rem;
  margin-left: 1.8rem;
  min-height: 80px;
  max-height: 200px;
  overflow-y: auto;
  background-color: rgba(243, 242, 242, 1);
  border-radius: 4px;
  font-size: 0.7rem;
}

.timelineCard__content__body table td {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}