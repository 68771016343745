.productsModal__body{
    /* background-color: rgba(112, 110, 107, 0.2); */
    background-color: rgb(250, 250, 249);
    max-height: 500px;
    overflow-y: scroll;
    padding: 0;
}
.productsModal__header {
    border-bottom: 1px solid white;
    padding: 1rem;
}
.productsModal__searchInput{
    height: 30px;
}
.productsModal_totalItems{
    padding: 00.25rem;
}

.productsModal_items{
    margin-top: 0.5rem;
    max-height: 300px;
    overflow-y: auto;
}

.productsModal__item{
    display: flex;
    align-items: center;
    background-color: white;
    min-height: 30px;
    margin-bottom: 0.25rem;
    border-radius: 5px;
    padding: 00.25rem;
}

.productsModal__itemIcon {
    display: flex;
    margin-right: 00.25rem;
    cursor: pointer;
    /* border-radius: 5px;
    border: 1px solid rgb(112, 110, 107, 0.3); */
    width: 25px;
    justify-content: center;
}

.modals_selectAll_container {
    display: flex;
    align-items: center;
}

.modals_selectAll_container input {
    margin: 0;
}

.modals_selectAll_container label {
    width: max-content;
}