.stepProgressBar{
    display: flex;
    position: relative;
    /* left: -20px */
}

.stepProgressBar_element{
    display: flex;
    width: 28%;
    height: 48px;
}

.stepProgressBar_elementContainer{
    width: 100%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.stepPBElement__title{
    background-color: #1589EE;
    font-weight: 600;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0;
    font-size: 1rem;
}
#triangle {
    border: 24px solid;
    border-color:transparent transparent transparent #1589EE;
}

#reverseTriangle {
    border: 24px solid;
    border-color:#1589EE #1589EE #1589EE transparent;
}

#divRadiusLeft{
    width: 24px;
    height: 48px;
    background-color: #1589EE;
    border-radius: 30px 0 0 30px;
}

#divRadiusRight{
    width: 24px;
    height: 48px;
    background-color: #1589EE;
    border-radius: 0 30px 30px 0;
}

.radiusStyleLeft {
    border-radius: 30px 0 0 30px;
}

.radiusStyleRight {
    border-radius: 0 30px 30px 0;
}

/* .stepProgressBar_element:after {
    content: "";
    position: relative;
    left: 130px;
    border: 24px solid;
    border-color:transparent transparent transparent black;
} */

