.workflow_button {
  position: relative;
  width: 29px;
  height: 24px;
  background-color: transparent;
  border: 1px solid lightgray;
  border-radius: 3px;
}
.modalSize {
  max-width: 1060px;
}
.red_back {
  background: rgb(241, 95, 95);
}
.blue_back {
  background: #1589ee;
}
.green_back {
  background: rgb(77, 202, 118);
}

.workflow_button i {
  color: white;
  position: absolute;
  top: 4px;
  bottom: 0;
  right: 0;
  left: 0;
}
.workflow_button:hover {
  cursor: pointer;
  color: white;
}
.workflow_button .fa-plus {
  color: #1589ee;
}

.hover_regie:hover {
  background: #1589ee;
}
.hover_annonceur:hover {
  background: rgb(241, 95, 95);
}
.hover_agence:hover {
  background: rgb(77, 202, 118);
}

.hover_regie:hover .fa-plus:hover:before {
  content: "\f164";
  color: white;
}
.hover_agence:hover .fa-plus:hover:before {
  content: "\f06e";
  color: white;
}
.hover_annonceur:hover .fa-plus:hover:before {
  content: "\f559";
  color: white;
}
.disabled{
  color: gray;
  cursor: auto;
}

.role .annonceur_active {
  color: white;
  background: rgb(241, 95, 95);
}
.role .regie_active {
  color: white;
  background: #1589ee;
}
.role .agence_active {
  color: white;
  background: rgb(77, 202, 118);
}

.roleHeaders {
  height: 39px;
}
.roleHeaders .name_header {
  width: 155px;
}
.roleHeaders .company_header {
  width: 167px;
}
.roleHeaders .mail_header {
  width: 239px;
}
.roleHeaders .type_header {
  width: 100px;
}
.roleHeaders .column-name {
  width: 148px;
}
.roleHeaders .column-company {
  width: 170px;
}
.roleHeaders .column-mail {
  width: 234px;
}
.roleHeaders .column-actor {
  width: 100px;
}
.roleHeaders .column-freespace {
  width: 10px;
}
.roleHeaders .annonceur_background {
  background: rgb(77, 202, 118);
  color: white;
}
.roleHeaders .regie_background {
  background: rgb(241, 95, 95);
  color: white;
}
.roleHeaders .agence_background {
  background: #1589ee;
  color: white;
}

.roleHeaders .role {
  width: 24px;
  padding: 7px 0px 10px 7px;
}
.roleHeaders .hrole {
  width: 36px;
}

.modal-container {
  min-width: 900px;
}
.modal-dim {
  min-width: 933px;
}

.body-container {
  padding: 5px;
  margin-top: 2px;
}
.body-container .body-modal {
  padding: 0px;
}
.body-container .body-modal .scroller-Table {
  overflow-y: auto;
  height: 300px;
}
.body-container .body-modal .scroller-Table table {
  font-size: small;
}

.body-container .body-modal .workflow-table {
  margin-bottom: 0px;
  font-size: small;
}
.body-container .search_bloc {
  display: flex;
  align-items: center;
  background: WhiteSmoke;
  padding: 5px 5px 5px 5px;
}

.workflow_advertiserContact_container{
  display: flex;
}

.body-container .search_bloc .advertiserContact_checkBox_container {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  width: max-content;
  white-space: nowrap;
}

.body-container .search_bloc label {
  color: black;
  margin-bottom: 2px;
}

.body-container .search_bloc .search {
  width: 40%;
}
.body-container .search_bloc input {
  width: 30px;
}
.body-container .search_bloc span {
  width: 38px;
}
.body-container .search_bloc .selected-items {
  margin-left: 10px;
}

.bar {
  background: whitesmoke;
  height: 40px;
  width: 100%;
}
.justify-content {
  padding: 3px !important;
  background-color: rgb(219, 233, 241);
}
.justify-content h5 {
  width: 250px;
  margin: auto;
}
.justify-content button {
  margin-left: 0 !important;
}
.actors-List {
  width: 100%;
  border-top: 1px solid rgb(212, 210, 210);
  border-bottom: 1px solid rgb(212, 210, 210);
  padding: 13px;
}
.actors-List h6 {
  width: 169px;
  margin: auto;
  padding: 0px;
}

.footer {
  padding: 8px 1px 1px 1px;
}
.footer .footerConainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.footer .footerConainer .roleContainer {
  width: 33%;
}
.footer .footerConainer .roleContainer h6 {
  border: 1px solid lightgray;
  background: whitesmoke;
  border-radius: 3px;
  margin-bottom: 2px;
  padding: 10px 10px 10px 20px;
}
.footer .footerConainer .roleContainer h6 i {
  padding: 4px;
  border-radius: 3px;
  color: white;
}
.footer .footerConainer .roleContainer h6 .dest_header {
  background: rgb(77, 202, 118);
  margin-right: 73px;
}
.footer .footerConainer .roleContainer h6 .regie_header {
  background: rgb(241, 95, 95);
  margin-right: 73px;
}
.footer .footerConainer .roleContainer h6 .annonceur_header {
  background: #1589ee;
} 
.footer .footerConainer .roleContainerContributor {
  width: 50%;
  padding: 0px 5px 0px 5px;
}
.footer .footerConainer .roleContainerContributor h6 {
  border: 1px solid lightgray;
  background: whitesmoke;
  border-radius: 3px;
  margin-bottom: 2px;
  padding: 10px 10px 10px 20px;
}
.footer .footerConainer .roleContainerContributor h6 i {
  padding: 4px;
  border-radius: 3px;
  color: white;
}
.footer .footerConainer .roleContainerContributor h6 .dest_header {
  background: rgb(77, 202, 118);
  margin-right: 73px;
}
.footer .footerConainer .roleContainerContributor h6 .regie_header {
  background: rgb(241, 95, 95);
  margin-right: 73px;
}
.footer .footerConainer .roleContainerContributor h6 .annonceur_header {
  background: #1589ee;
}

.documentTitle__icon{
  margin-right: 5px;
  margin-bottom: 10px;
  color: rgb(25, 98, 158);
}

.tableAction__icons{
  display: flex;
  justify-self: flex-end;
  justify-content: space-between;
}
.tableAction__action{
  display: flex;
  width: 30px;
  height: 29px;
  padding-top: 4px;
  justify-content: center;
  cursor: pointer;
  color: rgb(25, 98, 158);
  border: 1px solid white;
  
  border-radius: 5px;
  margin-right: 5px;
}
.tableAction__action > button{
  border: none;
 
  color: rgb(25, 98, 158);
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
}
.tableAction__action >button:hover{

}
.tableAction__icon{
  margin: 0 5px;
  font-size: 20px;
}
.gros{
  color: rgb(25, 98, 158);

}
.rest{

  min-width: 50px;
  background: #1589ee;
  display: flex;
  justify-content: center;
  color: white;
  text-align: center;
  border-radius: 5px;
  margin-left: 5px;
  font-size: large;
}
.signer_mandat {
  min-width: 199px;
  background: #1589ee;
  display: flex;
  justify-content: space-between;
  color: white;
  border-radius: 20px;
  margin-left: 23px;
  margin-right: 23px;
  margin-top: 12px;
}
.signer_mandat:hover {
  background: #0470ce;
  cursor: pointer;
}
.signer_mandat p {
  margin: auto;
}
.signer_mandat i {
  margin: auto;
}
.nowrap{
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}