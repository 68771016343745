.home_admSteps__header__header{
    border-bottom: 2px solid rgb(221, 219, 218);
}
.BodyNotifications{
    width: 20%;
    background-color: rgb(243, 242, 242);
    border: 1px solid rgb(221, 219, 218);
    margin-left: 20px;
}

.homeNotifications__headerHead{
    align-items: center;
    margin: 15px 10px 10px 10px;
}
.home_admSteps__steps{
    padding: 5px;
    display: flex;
}
.home_admSteps__steps label{
    margin: 0px 0px 0px 10px;
    border: 1px solid lightgray;
    border-radius: 5px;
    width: 90px;
    text-align: center;
}
.DRAFT{
    background-color: rgb(134, 142, 150);
}
.SIGNED{
    background-color: rgb(75, 192, 118);
}
.TO_BE_SIGNED{
    background-color: rgb(232, 213, 85);
}
.TO_BE_APPROVED{
    background-color: rgb(112, 110, 107);
}
.ABANDONED{
    background-color: rgb(192, 105, 75);
}
.EXPIRED{
    background-color: rgb(0, 0, 0);
}

.CANCELLED{
    background-color: red;
}



.home_admSteps{
    position: absolute;
    background: rgba(243, 242, 242,1);
    border: 2px solid #e8e8e8;
    color: #112732;
    top: -7px;
    left: -11px;
    border-radius: 5px;
    padding: 5px;
}
/* .home_admSteps::before{
    content: "";
    position: absolute;
    top: -25px;
    right: 37.3px;
    transform: translateX(-50%);
    border: 11px solid;
    border-color: transparent transparent #e8e8e8 transparent;
} */

.homeNotifications__headerLabel{
    display: flex;
    align-items: center;
}

.homeNotifications__headerLabelIcon{
    background-color: rgba(243, 110, 131, 1);
    color: white;
    padding: 2px;
    border-radius: 5px;
    margin-right: 10px;
}

.homeNotifications__headerLabel > h4 {
    font-size: 0.8rem;
    margin-top: 3px;
}
.homeNotifications__headerActionsIcon{
    background-color: white;
    border: 1px solid lightgray;
    border-radius: 5px;
    cursor: pointer; 
}

.homeNotifications__headerUnviewed{
    font-size: 0.7rem;
    color: gray;
    margin: 0 20px 10px 20px;
}
.homeNotifications__elementIcon{
    color: "#FFFFFF";
    border-radius: "5px";
    padding:"2px";
    font-size: "1rem";
    margin-right: 5px;
    font-size: 1.1rem !important;
}

.homeNotifications__typeHeader {
    padding: 5px 20px;
    font-size: 0.7rem;
    display: flex;
    align-items: center;
    color: gray;
    cursor: pointer;
    border-bottom: 2px solid rgb(221, 219, 218);
}
