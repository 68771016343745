.masterData_container{
    display: flex;
    justify-content: center;
}
.masterData_sides_container{
    display: flex;
    width: 90%;
    /* max-width: 881px; */
    /* max-height: 673px; */
    box-shadow: 5px 5px 5px rgb(0 0 0 / 35%);
    margin-top: 1rem;
}

.masterData_leftSide_container{
    width: 35%;
    height: 100%;
    background-color: #fafaf9;
}

.leftSide_header_container{
    display: flex;
    justify-content: space-between;
    margin: 1rem;
    height: 32px;
}
.header_actionTypes_container{
    display: flex;
    justify-content: space-between;
    height: 100%;
}
.header_actionTypes_items{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}
.header_actionTypes_icon{
    height: 100%;
    width: 32px;
    margin-right: 0.5rem;
}
.header_actionTypes_dropdown{
    height: 100%;
}
.header_actionTypes_dropdown > button{
    height: 100%;
    border-color: darkgray;
    width: 32px;
    padding: 0;
    margin-left: 0.5rem;
}
.header_addIcon_container{
    height: 100%;
    width: 32px;
    border: 1px solid darkgrey;
    border-radius: 0.25rem;
    cursor: pointer;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.leftSide_searchInput_container{
    margin: 1rem;
}
.searchInput_input_container{
    width: 100%;
    display: flex;
    position: relative;
}
.searchInput_input_icon{
    position: absolute;
    right: 0.5rem;
    align-self: center;
    cursor:pointer;
}

.clearInput_input_icon{
    position: absolute;
    right: 2rem;
    align-self: center;
    cursor:pointer;
}

.leftSide_listInfos_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 1rem 0.25rem 1rem;
}

.leftSide_listSort_container{
    padding: 0.25rem 1rem;
    border-top: 1px solid rgba(221, 219, 218, 1);
}

.listSort_container > span{
    margin-right: 0.5rem;
}

.leftSide_listElements_container{
    width: 100%;
    height: 70vh;
    margin-bottom: 9px;
    overflow: auto;
}
.listElement_container{  
    padding: 20px;
    border: 1px solid rgba(221, 219, 218, 1);
    border-right-style: none;
    cursor: pointer;
}
.listElement_container:hover{  
    /* padding: 20px; */
    /* margin: 20px 0; */
    border-left: 1px solid rgba(0, 112, 210, 1);
    border-left-width: 5px;
    background-color: white;
}
.listElement_container_selected{
    padding: 20px;
    border-left: 1px solid rgba(0, 112, 210, 1);
    border-left-width: 5px;
    background-color: white;
}

.listElements_container__element{
    display: flex; 
}

.pending_status{
    color: white;
    background-color: #1589EE;
    border-radius: 12px;
    margin-left: 8px;
    min-width: 21px;
    height: 19px;
    text-align: center;
}

.masterData_rightSide_container{
    width: 65%;
    height: 100%;
    border-left: 2px solid rgba(221, 219, 218, 1);
    padding: 3rem;
    /* overflow: auto; */
}

.rightSide_header_container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header_infos_container{
    display: flex;
    align-items: center;
}
.header_labels_container{
    display: flex;
    flex-direction: column;
}
.header_labels_container > strong{
    font-size: 1rem;
    font-weight: 600;
}
.header_labels_container > small{
    font-size: 0.7rem;
}
.rightSide_header_logo{
    width: 48px;
    height: 48px;
    margin-right: 1rem;
}
.rightSide_header_logo_big{
    width: 58px;
    height: 58px;
    margin-right: 1rem;
}


.rightSide_header_sentInvitation{
    color :rgba(0, 112, 210, 1);
}
.rightSide_header_pendingValidation{
    color :rgba(255, 99, 71, 1);
}

.header_select_container{
    width: 47%;
}
.rightSide_form_container{
    margin-top: 0.5rem;
}
.form_required_container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 1rem 0 0.5rem 0;
}

.form_required_asterisk{
    color: red;
    font-size: 1rem;
    margin-right: 0.25rem;
}

.form_twoElements_container{
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    align-items: center
}
.form_twoVerElements_container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 47%;
}
.form_element_container{
    position: relative;
    width: 47%;
}
.form_elementVer_container{
    width: 100%;
}
.form_element_container > label{
    margin-bottom: 0.25rem;
}
.form_element_container_filteragency{
    position: relative;
    width: 47%;
    align-self: normal;
}
.form_elementVer_container > label{
    margin-bottom: 0.25rem;
}
.form_oneElement_container{
    width: 100%;
    margin-bottom: 0.5rem;
}

.form_oneElement_container > label{
    margin-bottom: 0.25rem;
}
.form_element_product_header{
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: 0.25rem;
}
.form_element_product_header > label{
    /* margin-bottom: 0.40rem; */
    margin: 0;
}
.form_element_addProduct_container{
    display: flex;
    align-items: center;
    z-index: 100;
    position: absolute;
    left: 80px;
    width: 150px;
    height: 30px;
    top: -7px;
    background-color: white;
}
.form_element_addProduct_container > input{
    height: 100%;
}
.form_element_addProduct_icon{
    cursor: pointer;
    height: 100%;
    margin-left: -5px;
}
.addEntities_icon{
    margin-left: 0.5rem;
    color: #7edd7e;
    cursor: pointer;
}
.form_functions_departement{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.form_functions_departement div{
    width: 50%;
}
.form_functions_departement div > select{
    min-width: 48%;
}
.form_element_addSupport_container{
    display: flex;
    align-items: center;
    z-index: 100;
    position: absolute;
    left: 80px;
    width: 180px;
    height: 30px;
    top: -7px;
    background-color: white;
}
.form_element_addSupport_container > input{
    height: 100%;
}
.form_pillsElements_container{
    border: 1px solid rgba(221, 219, 218, 1);
    border-radius: 0.25rem;
    padding: 0.5rem;
    min-height: 100px;
    max-height: 167px;
    overflow: auto;
}

.form_company_pillsElements_container{
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(221, 219, 218, 1);
    border-radius: 0.25rem;
    padding: 0.5rem;
    min-height: 100px;
    max-height: 350px;
    overflow: auto;
}
.form_company_address_container{
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(221, 219, 218, 1);
    border-radius: 0.25rem;
    padding: 0.5rem;
    min-height: 100px;
    max-height: 350px;
    overflow: auto;
}
.company_address_closeIcon{
    display: flex;
    justify-content: flex-end;
}
.company_address_value{
    font-size: 0.75rem;
    color: #495057;
}
.form_pillElement_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 25px;
    border: 1px solid rgba(221, 219, 218, 1);
    border-radius: 0.25rem;
    padding: 0.25rem;
    margin-bottom: 0.25rem;
}
.form_pillElement_container_selected{
    background-color: #a8dcff;
    border: 1px solid #63aada;
}
.form_addressC_pillElmnt_container{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    /* height: 25px; */
    border: 1px solid rgba(221, 219, 218, 1);
    border-radius: 0.25rem;
    padding: 0.25rem;
    margin-bottom: 0.25rem;
}
.pillElement_infos{
    display: flex;
    align-items: center;
    overflow: hidden;
}
.pillElement_icon{
    height: 20px;
    width: 20px;
    margin-right: 0.5rem;
}

.pillElement_departmentIcon{
    height: 20px;
    width: 20px;
    margin-right: 0.5rem;
    background: palegreen;
    font-size: 1.1rem;
    padding: 0.1rem;
    color: white;
    border-radius: 20%;
}

.pillElement_label {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.entityUser_roles{
    margin-top: 0.5rem;
}

.rightSide_form_submit_container{
    display: flex;
    margin-top: 1rem;
    justify-content: flex-end;
}

.rightSide_form_submitBtn{ 
	margin-left: 10px;
}

.rightSide_askedBy_container {
    display: flex;
    align-items: center;
    color: rgb(133, 132, 131);
}

.infoIcon{
    cursor: pointer;
}

.askedBy_dropDown_container {
    /*background-color: rgba(204,204,0, 0.8);*/
    max-width: 300px;
    max-height: 260px;
    overflow: auto;
}

.listInfos_symbole_container{
    display: flex;
    align-items: center;
    margin-bottom: 0.25rem;
}

.listInfos_symbole_icon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 21px;
    padding: 0 0.5rem;
    border-radius: 15px;
    color: #ffffff;
}

.listInfos_symbole_definition {
    font-size: 0.8rem;
    color: rgb(133, 132, 131);
    margin-left: 0.25rem;
}

.form_company_brandActions_container {
    display: flex;
    align-items: center;
}

.company_codeservice_autorenewIcon{
    padding: 10px;
    position: absolute;
    box-sizing:border-box;
    top:50%;    
    transform: translateY(-50%);
    display: flex;
    align-content: center;
    flex-wrap: wrap;
}

