.ADMEdition_mandat_title{
    font-size: 0.8rem;
    font-weight: 600;
    padding: 0.5rem;
}

.ADMEdition_mandat_body{
    background-color: white;
    padding: 1rem;
}

.ADMEdition_mandatBody_periode{
    font-weight: bold;
    color: rgb(112, 110, 107);
}

.ADMEdition_mandatBody_sections{
    margin: 15px 0;
    display: flex;
    justify-content: space-between;
}

.ADMEdition_mandatBody_section{
    width: 32%;
}

.ADMEdition_mandatBody_sectionTitle{
    font-size: 0.7rem;
    color: rgb(112, 110, 107);
    margin-bottom: 5px;
}

.ADMEdition_mandatBody_sectionOptions{
    margin-bottom: 8px;
}

.ADMEdition_mandatBody_sectionAdd > button:first-child{
    font-size: 0.7rem;
    width: 100%;
    background-color: white;
    border-color: rgba(112, 110, 107, 0.5);
    margin-bottom: 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ADMEdition_mandatBody_sectionList{
    border: 1px solid rgba(112, 110, 107, 0.5);
    border-radius: 5px;
    width: 100%;
    padding: 0.25rem;
    min-height: 150px;
    max-height: 150px;
    overflow-y: auto;
}

.sectionListDisabled{
    background-color: #ddd;
    cursor: not-allowed;
}

.ADMEdition_mandatBody_sectionItem{
    padding: 0.25rem;
    display: flex;
    justify-content: space-between;
    border: 1px solid rgba(112, 110, 107, 0.5);
    font-size: 0.7rem;
    margin: 5px;
    color: rgba(112, 110, 107, 1);
    border-radius: 5px;
}

.ADMEdition_mandatBody_options {
    display: table;
    width: 100%;
}

.ADMEdition_mandatBody_optionsLabel{
    display: flex;
    justify-content: flex-end;
    color: rgba(112, 110, 107, 1);
    min-height: 20px;
    padding-top: 1px;
}

.ADMEdition_mandatBody_optionsHValue{
    display: flex;
    min-height: 20px;
}

.ADMEdition_mandatBody_optionsHValue h6 {
    font-size: 0.7rem;
    color: rgba(112, 110, 107, 1);
    margin-right: 5px;
    margin-bottom: 0;
}

.ADMEdition_mandatBody_optionsValue{
    display: flex;
    justify-content: space-around;
    min-height: 20px;
}

.purposesTable {
    width: 100%;
}

.purposesTable tbody tr td {
    color: rgba(112, 110, 107, 1);
    font-size: 0.7rem;
    text-align: center;
    vertical-align: middle;
    padding: 0 5px 0 5px;
}

.purposesTable tbody tr td:first-child {
    text-align: end;
    font-size: 0.8rem;
    min-height: 20px;
}

.purposesTable input {position: relative;}
