.header{
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 3px solid blue;
}

.nbEvents_element {
    position: absolute;
    background: rgb(21, 137, 238);
    border-radius: 10px;
    min-width: 19px;
    text-align: center;
    font-weight: bold;
    color: white;
    top: -11px;
    left: 6px;
}

.countMasterDatasPending {
    position: absolute;
    background: rgb(21, 137, 238);
    border-radius: 10px;
    min-width: 19px;
    text-align: center;
    font-weight: bold;
    color: white;
    top: -10px;
    left: 10px;
}

.header__container{
    width: 95%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
}

.header__logo{
    height: 100%;
    background-color: white;
    display: flex;
    align-items: center;
}

.header__menu{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-left: 25px;
}

.header_visualisationLogin_container{
    display: flex;
    width: fit-content;
    border: 2px solid #706E6B;
    border-radius: 0.25rem;
}

.header_visualisationLogin_content{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: baseline;
}

.header_visualisationLogin_value{
    padding: 0.25rem;
    font-weight: 600;
    color: #706E6B;
}

.header_visualisationLogin_exitIcon{
    cursor: pointer;
    background-color: #d1200d;
    padding: 0.25rem;
    color: white;
    height: 100%;
}

.header_visualisationLogin_exitIcon:hover{
    cursor: pointer;
    color: #d1200d;
    background-color: white;
    transition: all 0.3s ease;
}


.header__search{
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.header__actions{
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo__image{
    height: 90%;
}

.logo_title{
    font-size: 1.1rem;
    font-weight: 400;
    margin: 15px;
    overflow: visible;
    white-space: nowrap;
}

.nav__menu{
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    height: 100%;
    margin: 0;
}

.nav__item {
    height: 100%;
}

.nav__link {
    color: black;
    height: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    cursor: pointer;
    font-size: 0.8rem;
}

.nav__link:hover{
    border-bottom: 2px solid blue;
    background-color: #e7f3fd;
    text-decoration: none;
}
.nav__link__active {
    border-top: 3px solid blue;
    background-color: #e7f3fd;
}

.search__container {
    display: flex;
    background-color: white;
    align-items: center;
    border: 1px solid rgb(216, 221, 230);
    border-radius: 4px;
    padding: 5px 5px;
}

.search__icon {
    margin: 0 10px;
}

.search__input {
    outline: none;
    border-color: transparent;
    font-size: 0.8rem;
    width: 388px;
}

.header__action {
    margin: 0 10px;
    cursor: pointer;
    color: gray;
}

.header__action:hover {
    color: blue;
}

.header__action_active {
    color: blue;
}