.ADMView__pdfViewer_Container{
    max-height: 100vh;
}

.admViewer_download_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    width: 36px;
    height: 35px;
    padding: 8px;
}

.admViewer_download_icon:hover {
    background-color: rgba(0,0,0,0.1);
}
