
.destination_button {
    width: 19px;
    height: 18px;
    background-color: transparent;
    border: 0;
    border-radius: 3px;
    color: white;
    margin-right: 5px;
}
.green_back {
    background: rgb(77, 202, 118);
  }

.homeNotifications__header{
    border-bottom: 2px solid rgb(221, 219, 218);
}
.BodyNotifications{
    width: 20%;
    background-color: rgb(243, 242, 242);
    border: 1px solid rgb(221, 219, 218);
    margin-left: 20px;
}

.homeNotifications__headerHead{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 10px 10px 10px;
}

.homeNotifications{
    display: flex;
    flex-direction: column;
    position: absolute;
    background: rgba(243, 242, 242,1);
    border: 2px solid #e8e8e8;
    color: #112732;
    top: 61px;
    right: 91px;
    border-radius: 5px;
    min-width: 250px;
    width: 298px;
}
.homeNotifications::before{
    display: none;
    content: "";
    position: absolute;
    top: -25px;
    right: 27.3px;
    transform: translateX(-50%);
    border: 11px solid;
    border-color: transparent transparent #e8e8e8 transparent;
}

.homeNotifications__headerLabel{
    display: flex;
    align-items: center;
}

.homeNotifications__headerLabelIcon{
    background-color: rgba(243, 110, 131, 1);
    color: white;
    padding: 2px;
    border-radius: 5px;
    margin-right: 10px;
}

.homeNotifications__headerLabel > h4 {
    font-size: 0.8rem;
    margin-top: 3px;
}
.homeNotifications__headerActionsIcon{
    background-color: white;
    border: 1px solid lightgray;
    border-radius: 5px;
    cursor: pointer; 
}
.homeNotifications__lists{
    max-height: 400px;
    overflow-y: auto; 

}

.homeNotifications__headerUnviewed{
    font-size: 0.7rem;
    color: gray;
    margin: 0 20px 10px 20px;
}
.homeNotifications__elementIcon{
    color: "#FFFFFF";
    border-radius: "5px";
    padding:"2px";
    font-size: "1rem";
    margin-right: 5px;
    font-size: 1.1rem !important;
}

.homeNotifications__typeHeader {
    padding: 5px 20px;
    font-size: 0.7rem;
    display: flex;
    align-items: center;
    color: gray;
    cursor: pointer;
    border-bottom: 2px solid rgb(221, 219, 218);
}
