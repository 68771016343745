
.header__profil{
    position: relative;
}

.header__personIcon{
    color: #706E6B;
}

.header__personIcon:hover {
    cursor: pointer;
}

.header__profilDropdown{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    background: #ffffff;
    border: 2px solid #e8e8e8;
    color: #112732;
    top: 35px;
    right: -30px;
    padding: 10px 25px;
    border-radius: 5px;

}

.header__profilDropdown:before {
    content: "";
    position: absolute;
    top: -22px;
    right: 20px;
    transform: translateX(-50%);
    border: 10px solid;
    border-color: transparent transparent #e8e8e8 transparent;
}

.header__profilItem{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    white-space: nowrap;
}

.header__logoutBtn{
    white-space: nowrap;
    color: #706E6B;
    font-size: 1rem;
    font-weight: 600;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
}

.header__logoutBtn:hover {
    color: #1589EE;
    text-decoration: none;
}