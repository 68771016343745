.headerBar__container {
    width: 100%;
    display: flex;
    background-color: rgba(243, 242, 242,1);
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}
.new_mandat{
    background-color: white;
    border: 1px solid lightgray;
    color: #1589EE;
    margin-left: 8px;
}
.new_mandat:hover{
    background-color: rgb(243, 242, 242);
    color: #1589EE;
    border: 1px solid lightgray;
}

.grant_mandat{
    margin-top: 5px;
    background-color: white;
    border: 1px solid lightgray;
    color: #1589EE;
}
.grant_mandat:hover{
    background-color: rgb(243, 242, 242);
    color: #1589EE;
    border: 1px solid lightgray;
}


.headerBarHome__left {
    display: flex;
    justify-content: center;
    width: 80%;
}

.left__icon{
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(77, 202, 118);
    border-radius: 5px;
}
.headerBar__icon {
    color: rgb(243, 243, 243);
}

.left__elements{
    margin: 0 10px;
    width: 70%;
}

.leftElement_title{
    font-size: 1rem;
    margin: 0;
}
.leftElement_actions{
    display: flex;
    align-items: center;
}
.leftAction__title{
    font-size: 0.8rem;
    font-weight: 600;
    margin: 0;
}
.leftAction__icon{
    position: relative;
}

.headerBar__right{
    display: block;
    width: 20%;
}

.right__element{
    display: flex;
    background-color: white;
    justify-content: center;
    align-items: center;
    padding: 2px 15px;
    border-radius: 5px;
    cursor: pointer;
}

.right__element:hover {
    background-color: lightgray;
}
.rightElement__title{
    font-size: 0.8rem;
    color: blue;
    margin-left: 5px;
}

.headerBar__search{
    display: flex;
    margin-left: 25px;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.filterText__title{
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
    margin-left: 11px;
}

.headerBarSearch__container {
    display: flex;
    background-color: white;
    align-items: center;
    border: 1px solid rgb(216, 221, 230);
    border-radius: 4px;
    padding: 5px 0px;
    height: 34px;
    width: 100%;
    justify-content: space-between;
    min-width: 170px;
}

.headerBarSearch__icon {
    margin: 0 10px;
}

.headerBarSearch__input {
    outline: none;
    border-color: transparent;
    font-size: 0.8rem;
    width: calc(100% - 44px);
}

.headerBarFilter {
    margin-top: 19px;
}

.headerBarFilter__select {
    background-color: white;
    border: 1px solid rgb(216, 221, 230);
    border-radius: 4px;
    padding: 5px;
    height: 34px;
    margin-left:5px;
}

.headerBarFilter__selectEntity {
   width: 270px;
}

