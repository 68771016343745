.ADMSignature__container{
    padding: 1rem;
    width: 400px;
}

.ADMSignature__card_container{
    padding: 0.5rem;
    border: 1px solid rgba(112, 110, 107, 0.4);
    border-radius: 5px;
    margin-top: 0.5rem;
    
}

.ADMSignature__card_content {
    display: flex;
}

.ADMSignature__card_icon {
    /* max-width: 50px;
    max-height: 50px; */
    overflow: hidden;
}

.ADMSignature__card_infos {
    margin-left: 15px;
    width: 70%;
    /* color: rgba(112, 110, 107, 1); */
}

.ADMSignature__card_infosHead{
    display: flex;
    justify-content: space-between;
}

.ADMSignature__card_infosHead h5 {
    font-size: 1rem;
    font-weight: bold;
    color: royalblue;
}

.ADMSignature__card_address{
    /* color: rgba(112, 110, 107, 1); */
    margin-top: 10px;
}