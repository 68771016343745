.homeRegie {
    background-color: rgb(237, 244, 248);
    min-height: 100vh;
    display: flex;
    justify-content: center;
    width: 100%;
}

.homeRegie__container {
    width: 92%;
    margin-top: 25px;
}

.homeRegieBody__notifications{
    width: 20%;
    background-color: rgb(243, 242, 242);
    border: 1px solid rgb(221, 219, 218);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.homeRegieBodyAdm__signatures{
    margin-top: 15px;
}