.changesListDropDown_container {
    background-color: black;
    border: 1px solid #ced4da;
    position: absolute;
    width: 100%;
    z-index: 200;
}

.changesElement_container {
    background-color: white;
    padding: 00.25rem;
    cursor: pointer;
    border-bottom: 1px solid #ced4da;
}

.changesElement_container:hover {
    background-color: palegreen;
}