
.filterContainer{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.filter__input{
    width: 93%;
    padding: 6px 6px;
    border:2px solid rgb(38, 146, 235) ;
    border-radius: 4px;
    /* overflow: hidden;
    text-overflow: scroll; */
    white-space: nowrap;
    font-size: 0.8rem;
}
.filter__amountInterval{
    margin: 5px 3px 0 5px;
    width: 100%;
}
.filter__amountInterval .filter__input{
    height: 30px;
    width: 100%;
    
}
.filter__actions {
    display: flex;
}
.filter__input:focus{
    border: none;
}
.filter__select{
    margin:0 5px 0 0;
    padding: 0;
    border:2px solid rgb(38, 146, 235) ;
    border-radius: 4px;
    height:36px;
}
.filter__icon:hover{
    cursor: pointer;
}