.homeSearchView__homeBodyContainer{
    display: flex;
    width: 100%;
    margin-top: 30px;
    justify-content: space-between;
}

.homeSearchView__AdmList{
    width: 26%;
    background-color: rgb(250, 250, 249);
    border: 1px solid lightgray;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    height: 1000px;
    overflow: scroll;
}

.homeSearchView__AdmDetail {
    width: 79%;
    padding: 10px;
    background-color: white;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

button.collapsed > svg.homeSearchView__expand {
    display: none;
}

button:not(.collapsed) > svg.homeSearchView__collapse {
    display: none;
}