.MandatTopCard {
  width: 100%;
  box-sizing: border-box;
}
.MandatTopCard-div {
  width: 88%;
  margin: auto;
  border: 1px solid rgba(221, 219, 218, 1);
  border-radius: 4px;
}
.MandatTopCard-attest {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: rgba(243, 242, 242, 1);
  padding: 10px 20px 10px 20px;
}
.MandatTopCard-attest .workflow-buttons {
  margin-top: 6px;
}
.MandatTopCard-attest .workflow-buttons button {
  border: 1px solid lightgray;
  color: rgb(60, 138, 218);
  font-size: small;
  padding: 5px 37px 5px 37px;
}

.MandatTopCard-attest .attest-mandat {
  display: flex;
  justify-content: space-between;
}
.MandatTopCard .Mandat-details {
  width: 100%;
  display: flex;
  padding: 10px 10px 10px 20px;
  background: white;
}
.MandatTopCard .Mandat-details .periode-spec {
  min-width: 100px;
  margin-bottom: 0.5rem;
}
.MandatTopCard .Mandat-details .annonceur-spec {
  min-width: 255px;
  margin-bottom: 0.5rem;
}
.MandatTopCard .Mandat-details .description-spec {
  /* min-width: 345px; */
  width: 25%;
  margin-bottom: 0.5rem;
}
.MandatTopCard .Mandat-details .periode-spec .spec {
  color: #706e6b;
  margin-bottom: 0.5rem;
}
.MandatTopCard .Mandat-details .annonceur-spec .spec {
  color: #706e6b;
}
.MandatTopCard .Mandat-details .description-spec .spec {
  color: #706e6b;
}
.MandatTopCard .Mandat-details .annonceur-spec select {
  height: 26px;
  width: 190px;
  padding: 0px 0px 0px 4px;
}

.MandatTopCardHeader {
  color: #706e6b;
  font-family: "Roboto-Regular", "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
}

.MandatTopCardTitle {
  font-family: "Roboto-Regular", "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  color: #3e3e3c;
  text-align: left;
  line-height: 20px;
}

.MandatTopCardTitleBold {
  font-family: "Roboto-Medium", "Roboto Medium", "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  color: #3e3e3c;
  line-height: 23px;
}

.MandatTopCardRow {
  font-family: "Roboto-Regular", "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  color: #3e3e3c;
  text-align: left;
  line-height: 20px;
}
.MandatTopCardTop {
  background-color: rgba(243, 242, 242, 1);
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(221, 219, 218, 1);
  border-radius: 4px;
}

.MandatTopCard td {
  padding: 16px;
}

.ADMTopCard__item__label {
  display: flex;
  align-items: center;
}

.ADMTopCard__item__label_Description {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

.ADMTopCard__editIcon {
  cursor: pointer;
  height: 0.9rem !important;
  width: 0.9rem !important;
  margin-left: 0.5rem;
}

.ADMTopCard__editIcon:hover {
  color: rgb(60, 138, 218);
}

@media (min-width: 320px) and (max-width: 767px) {
  .MandatTopCard .Mandat-details .periode-spec {
    width: 100%;
    margin-bottom: 0.5rem;
  }
  .MandatTopCard .Mandat-details .annonceur-spec {
    width: 100%;
    margin-bottom: 0.5rem;
  }
  .MandatTopCard .Mandat-details .description-spec {
    /* min-width: 345px; */
    width: 100% !important;
    margin-bottom: 0.5rem;
  }
  .Mandat-details {
    flex-direction: column;
  }
}