.approvedEvent__content__header{
    display: flex;
    width: 90%;
    margin-bottom: 0.5rem;
}

.approvedEvent__header__name{
    margin-right: 25px
}

.approvedEvent__header__nameLabel{
    color: #706E6B;
}

.approvedEvent__header__nameValue{
    color: #0070D2;
}

.approvedEvent__header__companyLabel{
    color: #706E6B;
}

.approvedEvent__header__companyValue{
    color: #0070D2;
}