.tableBody__tr:nth-child(even) {
    background-color: #ebebeb;
}

.tableBody__tr {
    font-weight: 600;
    /* display: table;
    width: 100%;
    table-layout: fixed; */
    font-size: 12px;
    color: #666666;
    /*cursor: pointer;*/
}

.form-check-input {
    position: inherit;
}


.hoverList:hover>td {
    background-color: rgb(237, 244, 248) !important;
}



.tableBody_addIcon {
    cursor: pointer;
    color: rgb(21, 137, 238);
}

.tableBody_clearIcon {
    cursor: pointer;
    color: red;
}

.tableBody_editIcon {
    cursor: pointer;
}

.tableBody_visibilitIcon {
    cursor: pointer;
}