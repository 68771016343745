
.homeNotifications__header{
    border-bottom: 2px solid rgb(221, 219, 218);
}

.homeNotifications__headerHead{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 20px 10px 20px;
}

.homeNotifications__headerLabel{
    display: flex;
    align-items: center;
}

.homeNotifications__headerLabelIcon{
    background-color: rgba(243, 110, 131, 1);
    color: white;
    padding: 2px;
    border-radius: 5px;
    margin-right: 10px;
}

.homeNotifications__headerLabel > h4 {
    font-size: 0.8rem;
    margin-top: 3px;
}
.homeNotifications__headerActionsIcon{
    background-color: white;
    border: 1px solid lightgray;
    border-radius: 5px;
    cursor: pointer; 
}

.homeNotifications__headerUnviewed{
    font-size: 0.7rem;
    color: gray;
    margin: 0 20px 20px 20px;
}

.homeNotifications__typeHeader {
    padding: 5px 20px;
    font-size: 0.7rem;
    display: flex;
    align-items: center;
    color: gray;
    border-bottom: 2px solid rgb(221, 219, 218);
}
