.ADMCreationModal__body {
    width: 650px;
}

.ADMCreationModal__existingTable tbody tr {
    border: 1px rgb(221, 221, 221) solid;
}

.ADMCreationModal__existingTable tbody tr td {
    padding: 2px 5px;
}

.Liste_regies  
{
    padding-left: 20px;  
}