.invoiceHeader {
    margin-top: 14px;
}

.tableFixHead thead th {
    position: sticky;
    top: 0;
    z-index: 0;
}


.scroll-container {
    /* width: -webkit-fill-available;
    overflow-x: scroll;
    overflow-y: hidden;
    transform: rotate(180deg); */

    width: 100vw;
    /* height: 200px; */
    display: flex;
    flex-direction: row-reverse;
    /* Inverse l'ordre de l'affichage */
    overflow-x: auto;
    overflow-y: hidden;
    /* direction: rtl; */
}

.content {
    /* width: auto;
    transform: rotate(180deg); */

    width: 2000px;
    /* Exemple de large contenu */
    height: 100%;
    /* background: lightblue; */
}