.homeBody{
    width: 100%;
}

.homeBody__admListContainer {
    display: flex;
    position: relative;
    left: 21px;
}

.homeBody__admList{
    width: 24%;
    background-color: rgb(250, 250, 249);
    border-left: 1px solid lightgray;
}