.userProfile_container{
    display: flex;
    justify-content: center;
    min-height: 90vh;
}

.userProfile_sides_container{
    width: 60%;
    /* max-width: 881px; */
    /* max-height: 673px; */
    box-shadow: 5px 5px 5px rgb(0 0 0 / 35%);
    margin-top: 1rem;
    height: 100%;
}

.userProfile_rightSide_container{
    height: 100%;
    border-left: 2px solid rgba(221, 219, 218, 1);
    padding: 3rem;
    overflow: auto;
}

.personnalData__container {
    width: 100%;
    display: flex;
}

.personnalData__editableSide{
    width: 50%;
    border-right: 2px dashed #999998;
}

.personnalData__notEditableSide{
    width: 50%;
    padding: 0.5rem;
}

.notEditableSide__label_container{
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.notEditableSide__label_container label{
    margin: 0;
}

.form_threeElements_container{
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    width: 95%;
}

.form_element__container{
    /* display: flex; */
    margin-bottom: 1rem;
}
.form_element__container > label{
    /* width: 265px; */
    padding-top: 6px;
    font-size: inherit;
}
.rightSide_form_submit__container{
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
}

.userProfile_signatures_container{
    /* padding: 0.25rem; */
}

.signatures_body_container {
    padding: 0.25rem;
}

.signatures_list_container{
    display: flex;
    align-items: center;
    overflow-x: auto;
    height: 150px;
}

.signature_item_container {
    width: 100px;
    height: 100px;
    position: relative;
    margin-right: 0.25rem;
}

.signature_item_container img {
    display: block;
    width: 100px;
    height: 100%;
    border: 1px dashed #999998;
    border-radius: 5px;
}

.signature_delete_icon {
    position: absolute;
    top: -11px;
    cursor: pointer;
    color: #dc3545;
    right: 4px;
}

.signatures_canvas_container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;
}

.userProfile_signatureCanvas {
    cursor: pointer;
    border: 2px solid rgb(0, 121, 188);
    border-radius: 5px;
}

.userProfile_signatureCanvas:hover {
    border-style: dashed;
}

.form_element_config{
    /* display: flex; */
    /* justify-content: space-between; */
    width: 100%;
    border: 1px solid lightgray;
    border-radius: 3px;
    padding: 3px 7px;
    padding-bottom: 12px;
    margin-top: 37px;
    position: relative;
}


.form_element_config > h5{
    position: fixed;
    top: 196px;
    background: white;
}

.form_element_config_body{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1rem;
    flex-direction: column;
    align-items: baseline;
}


.form_element_config__container{
    width: 100%;
    display: flex;
    /* margin-right: 1rem; */
    margin-bottom: 1rem;
}

.form_element_config__showTel{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.form_element_config__showTel_label{
    color: #706E6B;
}

.form_element_config__showTel_value .toggleOffIcon{
    margin: 0 0.5rem;
    cursor: pointer;
    color: #dc3545;
}

.form_element_config__showTel_value .toggleOnIcon{
    margin: 0 0.5rem;
    cursor: pointer;
    color: #28a745;
}
 
.form_element_config__container > select{
    height: 28px;
    width: 60px;
    color: gray;
}
.form_element_config__container > input[type='checkbox']{
    height: 17px;
    width: 20px;
    margin-top: 6px;
    margin-right: 12px;
}
.form_element_config__container > label{
    width: 35%;
    padding-top: 6px;
    font-size: inherit;
}

.saveButton{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.saveButton > button{
    height: 31px;
    padding: 7px;
    padding-top: 3px;
    width: 106px;
}


.rightSide_form_submit__container{
    margin-top: 1rem;
    float: right;
}


