h6 {
  font-size: 20px;
}
.mandatEdition {
  width: 100%;
  justify-content: space-between;
  background: white;
  min-height: 400px;
}
.mandatEdition_navTabs {
  border-right: 1px solid lightgray;
  border-left: 1px solid lightgray;
  border-top: 1px solid lightgray;
  border-radius: 5px 5px 0 0;
}
.panel {
  display: flex;
}
.panel label {
  min-width: 170px;
  font-weight: 800;
  font-size: 12px;
  padding-top: 10px;
  color: #706e6b;
}
.checkbox-spec {
  margin-top: 7px;
}

.spec-checkbox label {
  padding-top: 0px !important;
}
.panel .rep-bloc {
  display: flex;
}
.panel .rep-bloc .rep-bloc-annonceur {
  margin-top: 10px;
}
.panel .sig-bloc .sig-bloc-annonceur {
  margin-top: 10px;
}
.panel .sig-bloc {
  display: flex;
}
.panel .rep-bloc label {
  min-width: 170px;
  font-weight: 800;
  font-size: 12px;
  padding-top: 10px;
  color: #706e6b;
}

.panel .sig-bloc label {
  min-width: 170px;
  font-weight: 800;
  font-size: 12px;
  padding-top: 10px;
  color: #706e6b;
}

.mandat-form {
  padding: 0px 10px 0px 10px;
  width: 100%;
  background: rgba(243, 242, 242, 1);
  overflow-y: scroll;
}
.mandat-form .form-title {
  text-align: center;
}
.mandat-form .form-title h3 {
  margin-bottom: 40px;
}

.mandat-form .form-adm {
  width: 89%;
  height: 800px;
  margin: auto;
  padding: 17px 10px 10px 10px;
}

.nav-item a {
  color: gray;
}
.nav-item a .active {
  color: rgb(47, 47, 218) !important;
}


/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(214, 209, 209);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(204, 199, 199);
}
