* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Encode Sans Expanded', sans-serif;
}

.App {
  margin: 0 25px;
}

@media (min-width: 320px) and (max-width: 767px) {
  
  .App {
    margin: 0;
  }
}