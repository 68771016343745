.header_icon_kpi {
    height: 100%;
    width: 32px;
    margin-right: 0.5rem;
    /* background-color: #1589EE; */
    /* background-color: rgb(207, 224, 238); */
    border-radius: 4px;
    /* color: white; */
    color: #1589EE;
    text-align: center;
    font-size: 22px;
}

.headerBar__containerkpi {
    width: 100%;
    display: flex;
    background-color: rgba(243, 242, 242, 1);
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}


.header_actionTypes_itemskpi {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.header_actionTypes_containerkpi {
    display: flex;
    justify-content: space-between;
    height: 100%;
}

.left__elementskpi {
    display: flex;
    margin: 0 10px;
    align-items: center;
}

.tableFixHead thead th {
    position: sticky;
    top: 0;
    z-index: 0;
}


.scroll-container {
    width: -webkit-fill-available;
    overflow-x: scroll;
    overflow-y: hidden;
    transform: rotate(180deg);
}

.content {
    width: auto;
    transform: rotate(180deg);
}