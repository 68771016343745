.userValidation_inputContainer{
    display: flex;
    align-items: center;
    height: 33px;
}

.userValidation_inputContainer > input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.userValidation_inputIcon{
    height: 100% !important;
    cursor: pointer;
    background-color:rgba(0,0, 0, 0.1);
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.userValidation_phoneInput {
    height: 33px !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}