.modal_w_mc {
    width: max-content;
}
.AgencySelection__header_container{
    display: flex;
}

h5{
    color: #706E6B;
}
.ADMCreationModal__AgencySelection_container{
    margin-bottom: 0.5rem;
}
.AgencySelection__selectedAgency{
    width: fit-content;
    min-width: 100px;
    background-color: rgba(226, 227, 229, 0.678);
    padding: 0.5rem;
    border-radius: 0.5rem;
    font-weight: 600;
}
.ADMCreationModal__advertiserType {
    width: 60%;
    margin-bottom: 20px;
}
.ADMCreationModal__advertiserType label {
    display: flex;
    align-items: center;
    font-size: 16px;
}
.ADMCreationModal__advertiserType input {
    margin-right: 5px;
}

.ADMCreationModal__description__container{
    margin-top:15px;
}


.hrElement{
    width: 80%;
    margin: 9px auto;
}

.bloc_container{
    display: flex;
    justify-content: space-between;
}
.advertiser-container{ 
    padding: 4px 5px;
    border: 1px solid lightgray;
    border-radius: 4px;
    background: #fbfbfb;
}