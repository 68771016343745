.tableBody__tr:nth-child(even){
    background-color: #ebebeb; 
}
.tableBody__tr{
    font-weight: 600;
    font-size: 12px;
    color: #666666;
}

.tableBody__content{
    max-width: 40vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
}
.tableBody__Amount{
    display: flex;
    justify-content: flex-end;
    padding-right: 43px;
}
.form-check-input {
    position:inherit;
}
.stepLabel >label {
    margin: 0px 9px 0px 10px;
    border: 1px solid lightgray;
    border-radius: 5px;
    width: 90px;
    text-align: center;
    padding-top: 0px;
}