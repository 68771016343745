.MandatBody{ 
    width: 88%; 
    margin: auto;
    margin-bottom: 30px;
    display: flex; 
    justify-content: space-between;
    margin-top: 17px;
    padding: 7px;
    background: white;
    border: 1px solid lightgray;
    border-radius: 3px;
}
.MandatBody .MandatEdition{
    padding: 8px;
    /* background: rgba(243, 242, 242, 1); */
    width: 65%;
    border-radius: 3px;
}
.MandatBody .TimeLine{
    padding: 6px;
    background: rgba(243, 242, 242, 1);
    width: 34%;
    border-radius: 3px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  
    .MandatBody {
        flex-direction: column;
    }
    .MandatBody .TimeLine {
      width: 100% ;
      padding: 1rem;
    }
    
}

  @media (min-width: 320px) and (max-width: 767px) {
  
    .MandatBody {
        flex-direction: column;
        width: 100%;
    }
    .MandatBody .MandatEdition{
        padding: 0;
    }
    .MandatBody .TimeLine {
    width: 100% ;
    padding: 1rem;
    }
    
  }