.modal_w_mc {
    width: max-content;
} 

h5{
    color: #706E6B;
}
 
.ADMCGrantModal__advertiserType {
    width: 40%;
    margin-bottom: 20px;
} 
  
.ADMCreationModal__adveriserIdentifier__container{
    width: 75%;
    margin-top:15px;
}   

.toast-custom-notyf {
  max-width: 50em !important; 
}

.toast-custom-notyf  .notyf__ripple {
    height: 60em;
    width: 60em;
}