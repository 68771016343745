
.homeNotifications__element{
    background-color: white;
    padding: 15px 13px 10px 13px;
    margin-bottom: 5px;
}

.homeNotifications_mandatLink{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.homeNotifications_adm{
    position: relative;
    padding: 3px 5px 5px 5px;
    margin-top: 5px;
}
.homeNotifications_adm:hover{
}

.homeNotifications__elementHeader{
    display: flex;
    justify-content: space-between;
    color: #6c6a6a;
}

.homeNotifications__elementLabel{
    display: flex;
    align-items: center;
}

.homeNotifications__elementIcon{
    background-color: rgba(243, 110, 131, 1);
    color: white;
    padding: 2px;
    border-radius: 5px;
    margin-right: 5px;
}

.homeNotifications__elementLabel > h6 {
    font-size: 0.8rem;
    margin: 0;
}

.homeNotifications__elementInfo{
    display: flex;
    justify-content: space-between;
    margin: 5px 0px;
}

.homeNotifications__elementInfo > h6{
    font-size: 0.8rem;
    color: gray;
}


.homeNotifications__elementBody .elementBody_message{
    padding: 5px;
    background-color: rgb(200 194 194 / 10%);
    border: 1px solid #d3d3d373;
    border-radius: 2px;
    color: #808080cf;
    margin: 0px 0px 14px 0px;
}

.homeNotifications__elementFooter{
    display: flex;
    align-items: center;
}

.homeNotifications__elementFooter > h6 {
    font-size: 0.8rem;
    color: gray;
    margin: 0;
}
.homeNotifications__elementFooter span{
    color: gray;
    font-size: 12px;
    /* margin: auto; */
    margin-top: 0.5px;
}