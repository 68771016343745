.productsModal__body{
    /* background-color: rgba(112, 110, 107, 0.2); */
    background-color: rgb(250, 250, 249);
    max-height: 500px;
    overflow-y: scroll;
    padding: 0;
}
.productsModal__header {
    border-bottom: 1px solid white;
    padding: 1rem;
}
.productsModal__searchInput{
    height: 30px;
}
.productsModal_totalItems{
    padding: 00.25rem;
    display: flex;
}

.productsModal_items{
    margin-top: 0.5rem;
    max-height: 300px;
    overflow-y: auto;
}

.productsModal__item{
    display: flex;
    align-items: center;
    background-color: white;
    min-height: 30px;
    margin-bottom: 0.25rem;
    border-radius: 5px;
    padding: 00.25rem;
}

.productsModal__itemIcon {
    display: flex;
    margin-right: 00.25rem;
    cursor: pointer;
    /* border-radius: 5px;
    border: 1px solid rgb(112, 110, 107, 0.3); */
    width: 25px;
    justify-content: center;
}

.productsModal__table {
    display: grid;
    /* grid-template-columns: 1fr 1fr 80%; */
     grid-template-columns: 1fr 1fr ;
    max-width: 480px;
}

.productsModal__divWwrapper{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.form_twoElements_container{
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
}

.form_twoElements_container_readonly {
    width: calc(100% - 140px);
    margin-left: 20px;
}

.form_element_container{
    position: relative;
    width: 47%;
}
.form_elementVer_container{
    width: 100%;
}
.form_element_container > label{
    margin-bottom: 0.25rem;
}

.ListManager_clearIcon{
    cursor: pointer;
    color: #e8e8e8;
}
.ListManager_clearIcon:hover {
    color: red;
}

.listManagerClause__dragIcon{
    cursor: grab;
    color: #e8e8e8;
}
.listManagertClause__dragIcon:active {
    cursor: grabbing;
    color: blue;
}

.listManager__actionsLeft > button {
    color: rgb(0, 116, 194);
}

.listManager__actionsRight .saveBtn {
    color: rgb(0, 116, 194);
}

.listManager__actionsRight .saveBtn:hover {
    color: #ffffff;
}

.listManager__actionsContainer{
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    border-right: 1px solid  lightgray;
    border-left: 1px solid  lightgray;
    border-bottom: 1px solid  lightgray;
    border-radius: 0 0 5px 5px;
    height: 50px;  
    align-items: center;
}

.listManager__actionsSelectContainer{
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    padding-bottom: 0px;
    padding-right: 15px;
    border-right: 1px solid  lightgray;
    border-left: 1px solid  lightgray; 
    align-items: center;
    height: 47px;

}

.listManager__actionsContainer button {
    font-size: 0.8rem;
}