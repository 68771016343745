.tableBody__tr:nth-child(even) {
    background-color: #ebebeb;
}

.tableBody__tr:nth-child(odd) {
    background-color: rgb(250, 250, 249);
}

.tableBody__tr {
    font-weight: 600;
    /* display: table;
    width: 100%;
    table-layout: fixed; */
    font-size: 12px;
    color: #666666;
    /*cursor: pointer;*/
}

.tableBody__companyName {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.tableBody__Amount {
    display: flex;
    justify-content: flex-end;
    padding-right: 43px;
}

.form-check-input {
    position: inherit;
}

.DRAFT {
    background-color: rgb(134, 142, 150);
}

.SIGNED {
    background-color: rgb(75, 192, 118);
}

.TO_BE_SIGNED {
    background-color: rgb(232, 213, 85);
}

.TO_BE_APPROVED {
    background-color: rgb(112, 110, 107);
}

.ABANDONED {
    background-color: rgb(192, 105, 75);
}

.EXPIRED {
    background-color: rgb(0, 0, 0);
}

.stepLabel>label {
    margin: 0px 0px 0px 0px;
    border: 1px solid lightgray;
    border-radius: 5px;
    width: 73px;
    text-align: center;
    padding-top: 0px;
}

tr.notPublished {
    color: #C6011F;
}