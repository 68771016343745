.messageEvent__userFrom{
    color: #0070D2;
}

.messageEvent__content__header{
    display: flex;
    width: 80%;
    justify-content: space-between;
    margin-bottom: 0.5rem;
}

.messageEvent__header__nameLabel{
    color: #706E6B;
}

.messageEvent__header__nameValue{
    color: #0070D2;
}

.messageEvent__header__companyLabel{
    color: #706E6B;
}

.messageEvent__header__companyValue{
    color: #0070D2;
}

.messageEvent__content__message{
    width: 90%;
}

.messageEvent__message__label{
    color: #706E6B;
    margin-bottom: 0.25rem;
}

.messageEvent__message__value{
    color: #3E3E3C;
}