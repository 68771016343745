.home {
    background-color: rgb(237, 244, 248);
    min-height: 100vh;
    display: flex;
    justify-content: center;
    width: 100%;
}

.home__container {
    width: 92%;
    margin-top: 25px;
}

.home__homeBodyContainer{
    display: flex;
    width: 100%;
    margin-top: 30px;
    justify-content: space-between;
}

.home__homeBodyMain{
    width: 100%;
}

.home__homeBodyNotifications{
    width: 20%;
    background-color: rgb(243, 242, 242);
    border: 1px solid rgb(221, 219, 218);
    margin-left: 20px;
}