body{
    font-size: small;
}
.container{
    background-color: white; 
    color: black; 
    margin-top:5%;
}
input{
    font-family: 'Roboto-Regular', 'Roboto', sans-serif;
    font-size: small;
}
.form-control {
    font-size: small;
    font-family: 'Roboto-Regular', 'Roboto', sans-serif;
}
p{
    margin: 0px;
    text-rendering: optimizeLegibility;
    font-feature-settings: "kern" 1;
    -webkit-font-feature-settings: "kern";
    -moz-font-feature-settings: "kern";
    -moz-font-feature-settings: "kern=1";
    font-kerning: normal;
}
.row-title{
    margin:15px;
}
.test{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: -15px;
    margin-left: -15px;
    flex-wrap: nowrap;
}
label{
    font-family: 'Roboto-Regular', 'Roboto', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    color: #706E6B;
    text-align: left;
    line-height: 18px;
    
}
#btnUser {
    border-width: 0px;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 32px;
    height: 32px;
    background: inherit;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(221, 219, 218, 1);
    border-radius: 4px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-family: 'FontAwesome Regular', 'FontAwesome', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    color: #54698D;
    line-height: 15px;
    margin: 10px;
}
.marque{
    color:#706E6B;
    padding-top:17px; 
    text-align:right;
    padding-left: 10px;
}
.titleUser{
    margin: 20px;
}
.cardContributor{  
border: 1px solid rgba(0,0,0,.125);
border-radius: .25rem;
margin-right: 1px; 
margin-left: 30px;
cursor: pointer;
min-height: 100px;
}
.input-icons .icon { 
    position: absolute; 
} 
  
.input-icons { 
    width: 100%; 
    /* margin-bottom: 10px;  */
    cursor: pointer;
} 
  
.icon { 
    padding: 10px; 
    color: green; 
    min-width: 50px; 
    text-align: center; 
} 
  
.input-field { 
    width: 100%; 
    padding: 10px;
    /* padding-left: 50px;  */
}
.callout{  
    padding: 20px;
    border: 1px solid rgba(221, 219, 218, 1);
    border-right-style: none;
    cursor: pointer;
}
.callout:hover{  
    /* padding: 20px; */
    /* margin: 20px 0; */
    border-left: 1px solid rgba(0, 112, 210, 1);
    border-left-width: 5px;
    background-color: white;
}
.active-icd-demo{
    border-left: 1px solid rgba(0, 112, 210, 1);
    border-left-width: 5px;
    background-color: white;
}
.listUser{
    padding: 20px;
    /* margin: 20px 0; */
    border: 1px solid rgba(0, 112, 210, 1);
}
.sortUser{
    border: 1px solid rgba(221, 219, 218, 1);
    border-right-style:none;
    border-bottom-style:none;
    padding: 7px;
    padding-left: 10px;
}
.cards-row{
    margin-right: 5px;
    margin-left: 5px;
    height:204px;
    width:110%;
    margin-left:-30px;
}



.rightSide{
    padding-top: 7%;
    padding-left: 4%;
    border-left-style:none;
    padding-bottom: 44px;
    border:solid solid none none;
    max-width: none;
    height: auto;
    min-width: 585px;
}
.left-side{
    background-color: #fafaf9;
    padding-top: 20px;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(221, 219, 218, 1);
    border-right-style:none;
    padding-bottom: 44px;

}

.icon-sideList{
    color:#706E6B;
    cursor: pointer;
}
.icon-refresh{
    color:#706E6B;
    cursor: pointer;
}
.icon-sideList:hover{
    color:blue;
}
.icon-refresh:hover{
    color:blue;
}
.btn-contributor-add {
    border-width: 0px;
    left: 0px;
    top: 0px;
    width: 90px;
    height: 32px;
    background: inherit;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(221, 219, 218, 1);
    border-radius: 4px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-family: 'Roboto-Regular', 'Roboto', sans-serif;
    font-weight: 400;
    font-style: normal;
    line-height: 15px;
    color: #0070D2;
}
.btn-contributor-add:hover {
    background-color: rgba(221, 219, 218, 1);
}

.btn-contributor-empty{
    width: 90px;
    margin:auto;
}
/* .row-contributor{
    margin-top:5px;
    height: 50px;
}
.row-contributor:hover{
    background-color:rgba(243, 242, 242, 1); ;
} */
.first-col-rowContributor{
    margin-top:5px;
    padding-left: unset;
    margin:auto;
}
.second-col-rowContributor{
    text-align: center;
    margin:auto;
}
.div-icon-user{
    width: 32px;
    height: 32px;
    border-radius: 4px;
    margin-right: 10px;
}

.btn-down{
    border-radius: 4px;
    border: 1px solid;
    width: 35px;
    height: 32px;
    display: flex;
    font-family: 'FontAwesome Regular', 'FontAwesome', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    color: #54698D;
    line-height: 15px;
    background-color: white;
    border-color: rgba(221, 219, 218, 1);
    cursor: pointer;
}
.btn-down:hover{
    background-color: rgba(221, 219, 218, 1);
    color: #0070D2;
}
.btn-down .fa-caret-down{
    margin:auto;
    font-size:130%;
} 
.img-btn-down{
    width: 12px;
    height: 7px;
    margin: auto;
}


.badge-entity {
    
    height: 26px;
    /* text-align: left; */
}

.badge-entity-child{
    height: 26px;
    background-color: rgba(255, 255, 255, 1);
    border: solid 1px;
    border-color: rgba(221, 219, 218, 1);
    border-radius: 4px;
}
.badgeE{
    justify-content: space-between;
    padding: 2px;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
}
.badgeE:hover{
    background-color: rgba(244, 246, 249, 1);
    cursor: pointer;
}
.badge-entity-icon-input {
    width: 23px;
    height: 23px;
    margin-left: -10px;
}
.badge-entity-icon {
    width: 20px;
    height: 20px;
}
.cross-icon{
    width: 11px;
    height: 11px;
    margin : auto;
    cursor: pointer;
}
.cross-icon-badgeMarque{
    width: 11px;
    height: 11px;
    margin-top : 4px;
    cursor: pointer;
}
.caret-input{
    margin-left: 2px;
    font-size: 100%;
}

.btn-send-invitation{
    height: 31px;
    padding: 5px;
    background-color: rgba(255, 255, 255, 1);
    border: solid 1px;
    border-top-color: currentcolor;
    border-right-color: currentcolor;
    border-bottom-color: currentcolor;
    border-left-color: currentcolor;
    border-color: rgba(221, 219, 218, 1);
    border-radius: 4px;
    cursor: pointer;
}

.btn-invitation-send{
    height: 31px;
    padding: 5px;
    background-color: rgba(255, 255, 255, 1);
    color :#0070D2;
    border-top-color: currentcolor;
    border-right-color: currentcolor;
    border-bottom-color: currentcolor;
    border-left-color: currentcolor;
    border-color: rgba(221, 219, 218, 1);
    border-radius: 4px;
    cursor: pointer;
}

@media all and (max-width: 1200px)
{
    .cards-row{
        margin-left: -23px;
        width: 109%;
    }
    .row-title {
        margin: 7px;    
    }
}
@media all and (max-width: 992px)
{
    .cards-row{
        margin-left:-60px;
        width: 112%;
    }
    .cardContributor{
        margin-right: -13px;
        margin-left: 33px;
    }
    .row-title {
        margin: 7px;
    }
}
@media all and (max-width: 768px)
{
    .cards-row {
        margin-left: -9%;
        width: 108%;
    }
    .row-title {
        margin: 1px;
    }
}

.add-address{
    border: 1px solid #ced4da;
    width:254pxpx;
    height: 118px;
    border-radius: 0.25rem;
    margin-left: 8px;
}
.add-address-sup{
    border: 1px solid #ced4da;
    width:auto;
    height: auto;
    border-radius: 0.25rem;
}
.div-addAddress-left
{
    margin-left: 15px;
}
.div-addAddress{
    margin-left: -8px;
}
.add-product{
    border: 1px solid #ced4da;
    width:250px;
    height: 130px;
    border-radius: 0.25rem;
}
.close-address-left{
    position: absolute;
    left: 90%;
}
.close-address-right{
    position: absolute;
    left: 80%;
}
.close-add-product{
    position: absolute;
    left: 90%;
}
.icon-search{
    position: absolute;
    right: 4%;
    padding-top:15px;
}
.leftSide{
    border: 1px solid;
    border-color: rgba(221, 219, 218, 1);
}