.admEdition__actionsContainer{
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    border-right: 1px solid  lightgray;
    border-left: 1px solid  lightgray;
    border-bottom: 1px solid  lightgray;
    border-radius: 0 0 5px 5px;
}

.admEdition__actionsContainer button {
    font-size: 0.8rem;
}

.admEdition__actionsLeft > button {
    color: rgb(0, 116, 194);
}

.admEdition__actionsRight .saveBtn {
    color: rgb(0, 116, 194);
}

.admEdition__actionsRight .saveBtn:hover {
    color: #ffffff;
}

.admEdition__addClauseIcon{
    cursor: pointer;
    color: #e8e8e8;
}
.admEdition__addClauseIcon:hover {
    color: rgb(77, 202, 118);
}

button.collapsed > svg.admEdition__expand {
    display: none;
}

button:not(.collapsed) > svg.admEdition__collapse {
    display: none;
}
