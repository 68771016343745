.grantDocumentRequestEvent_icon_container{
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    justify-content: center;
    /* background-color: #4DCA76; */
    /* background-color: grey; */
    background-color: rgb(141, 151, 168);
    
    color: #ffffff;
}