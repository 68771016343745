.signMandat_signatureTab{
    padding: 1rem 0.25rem;
    background-color: #FFFFFF;
}

.savedToDbMessage {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: large;
}