.signin__container{
    min-height: 692px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    overflow: hidden;
    background: white;
}
.signin__FromWrap{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.signin__Icon{
    margin-left: 32px;
    margin-top: 32px;
}
.signin_logo_img{
    height: 100px;
}
.signin__fromContent{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.signin__form{
    background: #ffffff;
    max-width: 400px;
    height: auto;
    width: 100%;
    z-index: 1;
    display: grid;
    margin: 0 auto;
    padding: 80px 32px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.9);
}
.signin__formH1{
    margin-bottom: 40px;
    color: #111111;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
}

.signin__error{
    text-align: center;
    color: red;
    font-weight: bold;
    margin-bottom: 20px;

}
.signin__formLabel{
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    color: #111111;
}
.signin__formInput{
    width: 100%;
    padding: 16px 16px;
    margin-bottom: 32px;
    border: none;
    border-bottom:2px solid #1589EE ;
    border-radius: 4px;
    outline: none;
}
.signin__formInput:focus{
    outline: none;
}
.signin__formButton{
    display: block;
    width: 100%;
    background: #1589EE;
    padding: 16px 0;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
}

.signin__formButton:hover {
    transition: all 0.2s ease-in-out;
    background: #ffffff;
    color: #1589EE;
    border: 2px solid #1589EE;
}

.forgotPWD__link{
    display: block;
    text-align: center;
    margin: 16px auto;
}