.ProgressLine {
  width: 100%;
  margin-top: 23px;
  box-sizing: border-box;
}
.ProgressLine-container-form {
  position: relative;
  height: 100px;
  border: 1px solid lightgray;
  margin: 40px 10px 10px 20px;
  display: flex;
}
.progressLine-container {
  width: 10%;
  margin: 0px 0px 0px 53px;
  height: 31px;
  background-color: white;
  display: flex;
  position: absolute;
  top: -16px;
}
.ProgressLine-div {
  height: 150px;
  width: 88%;
  margin: auto;
  border: 1px solid rgba(221, 219, 218, 1);
  border-radius: 4px;
  background: white;
}
.triangle_gray {
  width: 15px;
  height: 13px;
  border-top: 15.5px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 9px solid rgb(230 226 226);
  margin-right: -12px;
}
.rev_triangle_gray {
  height: 28px;
  border-top: 16px solid rgb(230 226 226);
  border-bottom: 15px solid rgb(230 226 226);
  border-right: 0px solid rgb(230 226 226);
  border-left: 8px solid transparent;
}
.pipe_gray {
  background: rgb(230 226 226);
  width: 133px;
  display: flex;
}
.pipe_gray p {
  margin: auto;
  color: white;
}

.triangle_blue {
  width: 15px;
  height: 13px;
  border-top: 15.5px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 9px solid #1589ee;
  margin-right: -12px;
}
.rev_triangle_blue {
  height: 28px;
  border-top: 16px solid #1589ee;
  border-bottom: 15px solid #1589ee;
  border-right: 0px solid #1589ee;
  border-left: 8px solid transparent;
}
.pipe_blue {
  background: #1589ee;
  width: 133px;
  display: flex;
}

.pipe_blue p {
    margin: auto;
    color: white;
}

.triangle_red {
    width: 15px;
    height: 13px;
    border-top: 15.5px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 9px solid #dc3545;
    margin-right: -12px;
}

.rev_triangle_red {
    height: 28px;
    border-top: 16px solid #dc3545;
    border-bottom: 15px solid #dc3545;
    border-right: 0px solid #dc3545;
    border-left: 8px solid transparent;
}

.pipe_red {
    background: #dc3545;
    width: 133px;
    display: flex;
}

.pipe_red p {
  margin: auto;
  color: white;
}

.triangle {
  width: 15px;
  height: 13px;
  border-top: 15.5px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 9px solid rgb(77, 202, 118);
  margin-right: -12px;
}
.rev_triangle {
  height: 28px;
  border-top: 16px solid rgb(77, 202, 118);
  border-bottom: 15px solid rgb(77, 202, 118);
  border-right: 0px solid rgb(77, 202, 118);
  border-left: 8px solid transparent;
}
.pipe {
  width: 133px;
  background: rgb(77, 202, 118);
  display: flex;
}

.signer_mandat {
  min-width: 199px;
  background: #1589ee;
  display: flex;
  justify-content: space-between;
  color: white;
  border-radius: 20px;
  margin-left: 23px;
}
.signer_mandat:hover {
  background: #0470ce;
  cursor: pointer;
}
.signer_mandat p {
  margin: auto;
}
.signer_mandat i {
  margin: auto;
}
.pipe p {
  margin: auto;
  color: white;
}
.radiusStyleRight {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.radiusStyleLeft {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.progressLine {
  display: flex;
}
.notice_container {
  position: absolute;
  bottom: 0;
  margin: 9px 10px 10px 18px;
}
.notice_container p {
}
.notice_container p i {
  margin-right: 17px;
  color: #1589ee;
}

.line_Hover {
  display: flex;
  cursor: pointer;
}
.line_Hover:hover > .pipe {
  background: rgb(61, 172, 98);
  content: "\f164";
}
.line_Hover:hover > .triangle {
  border-top: 15.5px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 9px solid rgb(61, 172, 98);
}
.line_Hover:hover > .rev_triangle {
  border-top: 16px solid rgb(61, 172, 98);
  border-bottom: 15px solid rgb(61, 172, 98);
  border-right: 0px solid rgb(61, 172, 98);
  border-left: 8px solid transparent;
}

.line_Hover:hover > .pipe_blue {
  background: #0470ce;
}
.line_Hover:hover > .triangle_blue {
  border-top: 15.5px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 9px solid #0470ce;
}
.line_Hover:hover > .rev_triangle_blue {
  border-top: 16px solid #0470ce;
  border-bottom: 15px solid #0470ce;
  border-right: 0px solid #0470ce;
  border-left: 8px solid transparent;
}

.line_Hover:hover > .pipe_gray {
  background: rgb(199, 196, 196);
}
.line_Hover:hover > .triangle_gray {
  border-top: 15.5px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 9px solid rgb(199, 196, 196);
}
.line_Hover:hover > .rev_triangle_gray {
  border-top: 16px solid rgb(199, 196, 196);
  border-bottom: 15px solid rgb(199, 196, 196);
  border-right: 0px solid rgb(199, 196, 196);
  border-left: 8px solid transparent;
}

.actionButtons {
  display: flex;
  height: 31px;
  margin-top: 10px;
}
