.modal_dialog_width{
    max-width: 1000px;
    min-width: min-content;
}

.publicatioModalRegieTable{
    table-layout: fixed;
    margin-bottom: 0;
}

.publicatioModalRegieTable td {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 0px;
}

.publicatioModalRegieTable tbody td, .publicatioModalRegieTable tbody th{
    padding: 8px 0px 0px 0px;
    border-right: 1px solid lightgray;
} 

.form-check {
    position: relative;
    display: block;
    padding-left: 2rem;
}

.publication {
    width: 100%; /* Optional */
    font-size: 12px;
}
.publication > thead, .publication > tbody { display: block; }
.publication > tbody {
    height: 500px;       
    overflow-y: auto;   
    overflow-x: hidden;  
}
.headerClick{
    border-left: 1px solid white ;
    border-right: 1px solid  white ;
}
.mediasCodeHeader{
    text-align:center;      
    overflow: hidden;
    cursor: pointer;
    color: white;
    padding: 5px !important;
}
.regieTableTh{
    border:none;
    width:57px;
    min-width: 57px;
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid rgb(222, 226, 230);
}
.regieTableNextTh{
    width:200px;
    min-width: 200px;
    max-width: 200px;
    color: #3d454fd4;
    text-transform: uppercase;
}
.regieTableFixedThAndTd{
    text-align:center;
}
.regieTableTr{
    background: aliceblue; 
    display: block
}
.regieTableTr2{
    background: aliceblue;    
}
.regieTableTd{
    width:57px;
    min-width: 57px;
    color: black;
    vertical-align: bottom;
    text-align: center;
}

.modal-open .modal {
    overflow-x: auto;
    overflow-y: auto;
}

/* .headerClick:hover{
    border-left: 1px solid white ;
    border-right: 1px solid  white ;
} */


.table-header-rotated {
    border-collapse: collapse;
}

.table-header-rotated td {
    width: 40px;
}

.table-header-rotated td {
    text-align: center;
    padding: 10px 5px;
    border: 1px solid #ccc;
}

.table-header-rotated th.rotate {
    height: 140px;
    white-space: nowrap;  
}

.table-header-rotated th.rotate div.div-rotated { 
    transform: translate(33px, 46px) rotate(315deg);
    width: 40px;
}  

.table-header-rotated th.rotate div.div-column-title {
    width: 180px  ;
    border-bottom: 1px solid #ccc;
    padding: 4px 7px 7px 0px;
    cursor: pointer;   
} 

.table-header-rotated th.rotate div span { 
    border-bottom: 1px solid #ccc; 
    padding: 4px 7px 7px 0px;
}

.table-header-rotated  td.row-header2 {
    padding: 0 10px;
    border-bottom: 1px solid #ccc;
}

.table-header-rotated  tbody td { 
    height:30px; 
} 
 
.table-header-rotated thead   {
    position: sticky; 
    top: 0;   
    right: 140;
    z-index: 100; 
    background: linear-gradient(white 0%,99%, #ccc 100%);  
} 

.table-header-rotated, .table-header-rotated th, .table-header-rotated td, ::after, ::before {
    box-sizing: content-box;
}
 
