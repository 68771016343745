.modificationsChat__container{

}

.modificationsChat__messages{
    display: flex;
    flex-direction: column;
    min-height: 140px;
    max-height: 700px;
    overflow: auto;
    padding: 0.5rem;
    border-bottom: 1px solid rgb(114, 112, 111, 0.2);
}

.modificationsChat__msgContainer {
    display: flex;
    margin-top: 00.25rem;
}

.modificationsChat__msgAvatar {
    border: 1px solid rgb(114, 112, 111, 0.2);
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem;
    background-color: rgba(114, 112, 111, 0.2);
    color: black;
}

.modificationsChat__msgValue {
    margin-left: 0.5rem;
}

.modificationsChat__msgValue p {
    width: fit-content;
    max-width: 200px;
    word-wrap: break-word;
    padding: 0.25rem;
    font-size: 0.8rem;  
}

.msgBorder {
    border-radius: 0 5px 5px 5px;
    background-color: rgba(114, 112, 111, 0.2);
}

.modificationsChat__msgValue h6 {
    font-size: 0.7rem;
    margin-top: 3px;
}

.myMessage  {
    flex-direction: row-reverse;
    margin-right: 5px;
    text-align: -webkit-right;
    text-align: -moz-right;
    text-align: -o-right;
    text-align: -ms-right;
}

.myMessage div p {
    background-color: rgb(200, 226, 255);
}

.msgBorder-reverse{
    border-radius: 5px 0 5px 5px;
    background-color: rgba(32, 80, 209, 0.2);
}

.modificationsChat__sendMsg{
    margin: 00.25rem;
    display: flex;
    width: 98%;
    border: 1px solid rgba(114, 112, 111, 0.2);
    border-radius: 20px;
    overflow: hidden;
}

.modificationsChat__sendMsg input {
    border: none;
    padding: 0.25rem;
    width: 100%;
}

.modificationsChat__sendMsg textarea {
    border: none;
    padding: 0.25rem;
    width: 100%;
}

.modificationsChat__sendMsg input:focus {
    border: none;
    outline: none;
}

.modificationsChat__sendMsg textarea:focus {
    border: none;
    outline: none;
}