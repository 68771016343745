.signatureTab__container{
    margin: 1rem;
}

.signatureTab__header {
    /* width: 393px; */
    margin-bottom: 1rem;
}
.signatureTab__header__title {
    /* font-size: 1.4rem; */
    font-weight: 600;
}

.signatureTab__header__resignButton__container{
    display: flex;
    justify-content: space-between;
}

.signatureTab__step {
    margin-bottom: 1.5rem;
}

.singatureTab__step_header {
    display: flex;
    align-items: center;
}

.signatureTab__stepNumber {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    min-width: 37px;
    min-height: 37px;
    border-radius: 50%;
    background-color: rgb(0, 121, 188);
    color: #FFFFFF;
    margin-right: 1rem;
}

.signatureTab__stepDoneIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    min-width: 37px;
    min-height: 37px;
    border-radius: 50%;
    background-color: rgba(77, 202, 118, 1);
    color: #FFFFFF;
    margin-right: 1rem;
}

.signatureTab__stepCheckbox__container {
    display: flex;
    align-items: center;
}

.step__checkbox__input {
    min-height: 16px;
    min-width: 16px;
}

.step__checkbox__label {
    margin: 0;
    color: rgb(0, 121, 188);
    font-size: 0.9rem;
    font-weight: 600;
    margin-left: 0.5rem;
}

.stepContent__identityContent {
    margin-left: 5rem;
    width: 200px;
}

.signatureTab__canvasSignContent{
    max-width: min-content;
    margin-left: 2.5rem;
    margin-top: 0.5rem; 
    border-radius: 5px;
}

.stepContent__identityInput {
    margin-bottom: 0.5rem;
    background-color: rgba(242, 242, 243, 1) !important;
}

/* Step 2 */
.step__header__title {
    font-size: 0.9rem;
    font-weight: 600;
} 

/* sign button */

.signatureTab__signButton__container {
    display: flex;
    justify-content: center;
}

.signatureTab__signButton{
    background-color: rgba(77, 202, 118, 1);
    width: 108px;
}

.signatureCanvas_btnActions {
    display: flex;
    
}

.signaturesList_container{
    position: relative;
}
@media (min-width: 320px) and (max-width: 767px) {
  
    .signatureCanvas {
        touch-action: none;
    }
  }