.invoiceHeader{
    margin-top: 14px;
}

.header_icon_kpi{
    height: 100%;
    width: 32px;
    margin-right: 0.5rem; 
    border-radius: 4px; 
    color: #1589EE;
    text-align: center;
    font-size: 22px;
}

.headerBar__containerkpi {
    width: 100%;
    display: flex;
    background-color: rgba(243, 242, 242,1);
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.tableFixHead thead th { position: sticky; top: 0; z-index: 0; }


