.askedBy_container {
    display: flex;
    align-items: center;
    color: rgb(133, 132, 131);
    
}

.infoIcon{
    cursor: pointer;
}

.askedBy_changeValue {
    font-size: 1rem;
    color: white;
    background-color:black;
    padding: 5px;
    margin-bottom: 0;
    font-weight:bold;
}

.askedBy_container .askedBy_dropDown_container {
    padding: 0.25rem;
    background-color: #FFFFFF;
    /* background-color: rgba(204,204,0, 0.8); */
    max-width: 300px;
    max-height: 260px;
    overflow: auto;
}

.askedBy_dropDownList li{
    list-style-type: upper-roman;
}