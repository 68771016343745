.ADMEdition_annonceur_title{
    font-size: 0.8rem;
    font-weight: 600;
    padding: 0.5rem;
}

.ADMEdition_annonceur_body{
    background-color: white;
    padding: 1rem;
}

.ADMEdition_annonceur_infoItems{
    display: flex;
    justify-content: space-between;
}

.ADMEdition_annonceur_infoItemsSubLine{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.ADMEdition_annonceur_infoItem{
    width: 30%;
}

.ADMEdition_annonceur_addresses{
    margin-top: 20px;
    margin-bottom: 20px;
}

.ADMEdition_annonceur_address{
    margin-top: 15px;
}

.ADMEdition_annonceur_addressTitle label{
    margin: 0;
}

.ADMEdition_annonceur_addressTitle{
    display: flex;
    align-items: center;
}

.ADMEdition_annonceur_addressInput textarea{
    height: 73px;
}

.ADMEdition_annonceur_addressInput{
    width: 80%;
    display: flex;
    align-items: center;
}

.ADMEdition_annonceur_representantsList{
    display: flex;
    flex-flow: wrap;
}

.ADMEdition_annonceur_representantsTitle{
    color: #706E6B;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.ADMEdition_annonceur_representantContainer{
    padding: 1rem;
    border: 1px solid lightgray;
    border-radius: 5px;
    width: 260px;
    height: 103px;
    /* margin-right: 20px; */
    min-width: 150px;
    margin-top: 25px;
}

.ADMEdition_representant_delIcon {
    width: 25px;
    height: 25px;
    position: relative;
    top: -28px;
    right: -120px;
}

.ADMEdition_annonceur_representantName{
    padding: 0.3rem 0.5rem;
    border: 1px solid lightgray;
    border-radius: 5px;
    margin-bottom: 5px;
}

.ADMEdition_annonceur_representantFunction{
    padding: 0.3rem 0.5rem;
    border: 1px solid lightgray;
    border-radius: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
    
}
.AdmEdition_clearIcon{
    cursor: pointer;
    color: #e8e8e8;
}
.AdmEdition_clearIcon:hover {
    color: red;
}
.form-control:disabled {
    background-color: white;
}