.dropDownSearchInput{
    height: 30px;
}

.dropDownSearch__title{
    font-size: 0.7rem;
    color: rgb(112, 110, 107);
}

.dropDownSearch__content{
    margin-top: 10px;
    max-height: 250px;
    overflow: auto;
}

.dropDownSearch__itemIcon{
    display: flex;
    align-items: center;
    background-color: rgba(149, 174, 197, 1);
    padding: 4px;
    border-radius: 5px;
    color: white;
}
.dropDownSearch__item{
    display: flex;
    align-items: center;
    margin-top: 10px;
    height: 47px;
    padding: 0.25rem;
}
.dropDownSearch__item:hover {
    background-color: rgba(243, 242, 242, 1);
}
.dropDownSearch__item:active {
    background-color: rgb(200, 244, 255);
}
.dropDownSearch__value{
    width: 90%;
    margin-left: 5px;
}

.dropDownSearch__value h6{
    font-size: 0.7rem;
    color: rgb(77, 75, 74);
    margin: 0;
}

.dropDownSearch__value p{
    font-size: 0.7rem;
    color: rgb(77, 75, 74);
}