.creationEvent__content__body{
    font-size: 0.7rem;
}

.creationEvent_item_label{
    color: #706E6B;
}
.creationEvent__item{
    margin-bottom: 1rem;
}
.creationEvent_item_value{
    color: #0070D2;
}

.creationEvent_item_description{
    color: #3E3E3C;
}