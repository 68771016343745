.admInfoCard__container {
    width: 92%;
    margin: auto;
    margin-bottom: 10px;
    margin-top: 10px;
    border: 1px solid lightgray;
    font-size: 0.8rem;
    background-color: white;
    border-radius: 5px;
}

.admInfoCard__container:hover {
    border: 1px solid rgb(102, 163, 221);
}

.admInfoCard__containerActive {
    box-shadow: 0 0 3px 2px rgb(102, 163, 221);
    border: 1px solid rgb(102, 163, 221);
}

.admInfoCard__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 10px;
    font-size: 0.9rem;
    font-weight: 600;
    color: #1589EE !important;
}

.admInfoCard__header label {
    margin: 0px 9px 0px 10px;
    border: 1px solid lightgray;
    border-radius: 5px;
    width: 90px;
    text-align: center;
    padding-top: 0px;
}

.actors_block_list table tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.actors_block_list table tbody {
    display: block;
    max-height: 260px;
    min-height: 100px;
    overflow-y: auto;
}

.nb_events {
    color: white;
    background-color: #1589EE;
    border-radius: 12px;
    margin-left: 8px;
    min-width: 21px;
    height: 19px;
    text-align: center;
}

.has_action {
    color: white;
    background-color: #f5a30a;
    border-radius: 12px;
    padding: 0 6px;
    height: 19px;
    text-align: center;
}

.has_grantAccessRequest {
    color: white;
    background-color: #f5a30a;
    border-radius: 12px;
    padding: 0 4px;
    /* height: 19px; */
    text-align: center;
}

.signatureResign {
    color: white;
    background-color: #ff0000;
    border-radius: 12px;
    padding: 0 6px;
    height: 19px;
    text-align: center;
}

.has_action {
    color: white;
    background-color: #f5a30a;
    border-radius: 12px;
    padding: 0 6px;
    height: 19px;
    text-align: center;
}

.has_action {
    color: white;
    background-color: #f5a30a;
    border-radius: 12px;
    padding: 0 6px;
    height: 19px;
    text-align: center;
}

.hasChatPending_clearIcon {
    cursor: pointer;
    color: #e8e8e8;
}

.hasChatPending_clearIcon:hover {
    color: red;
}


.link_spec {
    font-weight: 400;
    color: #4e4e4e;
}

.options_info {
    padding: 10px;
}

.options-info p {
    margin: 0;
}

.options_info p:hover {
    background-color: rgb(243, 242, 242);
}

.cardInfo {
    padding: 7px;
    font-size: 12px;
}

.cardInfo:hover {
    width: 100%;
    background-color: rgb(243, 242, 242);
}

.cardInfo a:hover {
    color: #4e4e4e;
    text-decoration: none;
}

.admInfoCard__headerDropDown {
    position: relative;
}

.admInfoCard__headerDropDownActions {
    position: absolute;
    background-color: white;
    width: 169px;
    border: 1px solid lightgray;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}

.admInfoCard__headerDropDownActions hr {
    margin: 2px
}

.admInfoCard__headerDropDownAction {
    cursor: pointer;
}

.admInfoCard__element {
    display: flex;
    margin: 2px 10px;
}

.admInfoCard__elementLabel {
    min-width: 100px;
    color: gray;
}

.admInfoCard__elementValue {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.admInfoCard__status {
    color: white;
    font-size: 10px;
    display: inline-block;
    border-radius: 3px;
    width: 73px;
    text-align: center;
    padding: auto;
    cursor: pointer;
}

.admInfoCard__status:hover {
    background-color: rgba(114, 123, 132) !important;
}

.admInfoCard__creator {
    background-image: linear-gradient(rgba(255, 255, 255, 0.5) 50%, rgb(30, 144, 255, 0.4) 100%);
}

.DRAFT {
    background-color: rgb(134, 142, 150);
}

.SIGNED {
    background-color: rgb(75, 192, 118);
}

.TO_BE_SIGNED {
    background-color: rgb(232, 213, 85);
}

.TO_BE_APPROVED {
    background-color: rgb(112, 110, 107);
}

.ABANDONED {
    background-color: rgb(192, 105, 75);
}

.EXPIRED {
    background-color: rgb(0, 0, 0);
}