/* .aeListGuide_helpIcon:hover {
    color: royalblue;
} */

.aeListGuide_symbole_container{
    display: flex;
    align-items: center;
    margin: 0.5rem;
}

.aeListGuide_symbole_icon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 21px;
    padding: 0 0.5rem;
    border-radius: 15px;
    color: #ffffff;
}

.aeListGuide_symbole_definition {
    font-size: 0.8rem;
    color: rgb(133, 132, 131);
    margin-left: 0.25rem;
}